.educator-container {
    max-width: 600px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 125px;
    padding: 30px;
    background-color: #1e1e1e;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);

  }
  
  h1 {
    text-align: center;
    color: #ffffff;
  }
  
  form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .dropzone {
    border: 2px dashed #424242;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    background-color: #2c2c2c;
    color: #e0e0e0;
    cursor: pointer;
  }
  
  .dropzone p {
    margin: 0;
  }
  
  input[type="file"] {
    display: none;
  }
  
  label {
    margin-bottom: 5px;
    color: #ffffff;
  }
  
  input[type="number"] {
    padding: 10px;
    border: 1px solid #424242;
    border-radius: 4px;
    background-color: #2c2c2c;
    color: #e0e0e0;
  }
  
  button[type="submit"] {
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #6200ea;
    color: #ffffff;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button[type="submit"]:hover {
    background-color: #3700b3;
  }
  
  .error {
    color: #ff5252;
    text-align: center;
  }
  
  .result {
    text-align: center;
    margin-top: 20px;
  }
  
  .result h2 {
    color: #ffffff;
  }
  
  .result a {
    color: #bb86fc;
    text-decoration: none;
  }
  
  .result a:hover {
    text-decoration: underline;
  }
  