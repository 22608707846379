.login-bg {
  position: relative;
  width: 100%;
  height: 100vh;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-container {
  position: relative;
  margin-top: 100px;
  width: 550px; /* Increased width for better spacing */
  padding: 40px; /* Increased padding for better spacing */
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px; /* Rounded corners for container */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Box shadow for depth */
}

.login-title {
  width: 80%;
  margin-bottom: 20px;
}

.login-input {
  width: 100%;
  padding: 15px;
  margin-bottom: 10px;
  border: none;
  outline: none;
  font-size: 20px;
  background: transparent;
  background-image: url('./ALLBOX.webp');
  background-size:contain;
  background-repeat: no-repeat;
  text-align: leftS; /* Center the text horizontally */
  color: white; /* Adjust text color to be visible on the background */
  border-radius: 5px; /* Rounded corners for input */
}

.login-button-image {
  width: 100%;
   /* Limit the button width for better presentation */
  height: 70px; /* Adjust height to match the background image */
  cursor: pointer;
  border: none;
  outline: none;
  background: transparent;
  background-image: url('./BUTTON.webp');
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center; /* Center the text horizontally */
  color: transparent; /* Hide the text */
}

.login-button-image:hover {
  background-color: rgba(0, 0, 0, 0.1); /* Add a slight overlay on hover */
}

.login-error {
  color: red;
  margin-top: 10px;
}

.login-signup-link p {

  color: white;
}

.login-signup-link p a {
  color: #b988ff;
  text-decoration: none;
}
@media screen and (min-width: 1024px) and (max-width: 1440px){

  .login-container{
    position: relative;
    margin-top: 100px;
    width: 500px;
    padding: 40px;
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  .login-bg {
    position: relative;
    width: 100%;
    height: 100vh;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .login-title {
    width: 80%;
    margin-bottom: 20px;
  }
  
  .login-input {
    width: 100%;
    border: none;
    outline: none;
    font-size: 20px;
    background: transparent;
    background-image: url('./ALLBOX.webp');
    background-size: contain;
    background-repeat: no-repeat;
    text-align: center;
    color: white;
    border-radius: 5px;
  }
  
.login-button-image {
  width: 100%;
   /* Limit the button width for better presentation */
  height: 60px; /* Adjust height to match the background image */
  cursor: pointer;
  border: none;
  outline: none;
  background: transparent;
  background-image: url('./BUTTON.webp');
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center; /* Center the text horizontally */
  color: transparent; /* Hide the text */
}

.login-button-image:hover {
  background-color: rgba(0, 0, 0, 0.1); /* Add a slight overlay on hover */
}

.login-error {
  color: red;
  margin-top: 10px;
}

.login-signup-link p {
  color: white;
}

.login-signup-link p a {
  color: #b988ff;
  text-decoration: none;
}
}

@media screen and (min-width: 475px) and (max-width: 768px){

  .login-container{
    position: relative;
    margin-top: 100px;
    width: 450px;
    padding: 40px;
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  .login-bg {
    position: relative;
    width: 100%;
    height: 100vh;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .login-title {
    width: 80%;
    margin-bottom: 20px;
  }
  
  .login-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 40px;
    margin-top: 10px;
    border: none;
    outline: none;
    font-size: 20px;
    background: transparent;
    background-image: url('./ALLBOX.webp');
    background-size:contain;
    background-repeat: no-repeat;
    text-align: center; /* Center the text horizontally */
    color: white; /* Adjust text color to be visible on the background */
    border-radius: 5px; /* Rounded corners for input */
  }
  
.login-button-image {
  width: 100%;
   /* Limit the button width for better presentation */
  height: 60px; /* Adjust height to match the background image */
  cursor: pointer;
  margin-top: 100px;
  border: none;
  outline: none;
  background: transparent;
  background-image: url('./BUTTON.webp');
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center; /* Center the text horizontally */
  color: transparent; /* Hide the text */
}

.login-button-image:hover {
  background-color: rgba(0, 0, 0, 0.1); /* Add a slight overlay on hover */
}

.login-error {
  color: red;
  margin-top: 10px;
}

.login-signup-link p {
  color: white;
}

.login-signup-link p a {
  color: #b988ff;
  text-decoration: none;
}
}

@media screen and (min-width: 320px) and (max-width: 384px) {
  .login-container {
    margin-top: 50px; /* Adjusted for better spacing */
    width: 90%; /* Flexible width */
    padding: 20px; /* Increased padding for better spacing */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .login-input {
    width: 100%; /* Full width for input */
    padding: 8px; /* Reduced padding for better fit */
    margin-left: 0; /* Centered input */
    margin-top: 10px;
    font-size: 16px; /* Reduced font size for better fit */
    text-align: center;
  }
  
  .login-button-image {
    width: 100%;
    height: 50px; /* Adjusted height for better fit */
    margin-top: 30px; /* Adjusted margin for better spacing */
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: 80px;
  }
  
  .login-title{
    width: 70%; /* Ensure all elements take full width */
    text-align: center; /* Center align text */
  }

  .login-error, .login-signup-link p {
    width: 100%; /* Ensure all elements take full width */
    text-align: center; /* Center align text */
  }
}

@media screen and (min-width: 385px) and (max-width: 474px) {
  .login-container {
    margin-top: 50px; /* Adjusted for better spacing */
    width: 80%; /* Flexible width */
    padding: 20px; /* Increased padding for better spacing */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .login-input {
    width: 100%;
    padding: 8px;
    margin-left: 0;
    margin-top: 15px;
    text-align: center;
    font-size: 16px;
  }
  
  .login-button-image {
    width: 100%;
    height: 50px; /* Adjusted height for better fit */
    margin-top: 80px; /* Adjusted margin for better spacing */
    background-size: contain;
    background-repeat: no-repeat;
  }
  
  .login-title {
    width: 70%; /* Ensure all elements take full width */
    text-align: center; /* Center align text */
  }
  .login-error, .login-signup-link p {
    width: 100%; /* Ensure all elements take full width */
    text-align: center; /* Center align text */
  }
}
