.container {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two columns */
    gap: 20px;
    width: 100%;
    max-width: 2000px;
    margin: 20px;
    padding: 20px;
  }
  
  .chat-section {
    display: flex;
    flex-direction: column;
  }
  
  .text-input {
    width: 100%;
    margin-bottom: 20px;
  }
  
  .result-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  