.css-4t3x6l-MuiPaper-root-MuiDrawer-paper {
    background-color: black;
}

.usernameimg {
    width: 150px;
}
.username {
    display: none;
}
.log {
    width: 150px;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
    .css-7v40v5-MuiStack-root {
        margin-left: 0px;
    }
    .css-1m04nb5 {
        margin-left: 50px;
    }
    .username {
        display: block;
    }
}
@media screen and (min-width: 320px) and (max-width: 475px) {
.logo{
    display: none;
}
.IconButton{
    margin-right: 75px;
    display: flex;
    left: -40px;
}
.css-1vadud5-MuiTypography-root  {
    display: none;
}
.usernameimg {
    display: none;
    visibility: hidden;
}
.username {
    display: none;
}
.css-1m04nb5 {
    margin-left: 20px;
}
.css-yjxcff{
    display: none;
}
}
@media screen and (min-width:476px) and (max-width: 769px){
    .css-1m04nb5 {
        margin-left: 20px;
    } 
    .username {
        display: block;
    }
}
@media screen and (max-width: 1024px) and (min-width: 769px) {
    .css-13420go {
        margin-right: 0px;
    }
    .css-70qvj9 {
        margin-left: 0px;
    }
    .usernameimg {
        height: 30px;
        width: 100px;
    }
    .log {
        width: 100px;
    }
    .username {
        display: block;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1440px) {
    .css-13420go {
        margin-right: 0px;
    }
    .css-70qvj9 {
        margin-left: 0px;
    }
    .usernameimg {
        width: 150px;
    }
    .username {
        display: block;
    }
    .log {
        width: 150px;
    }
}

@media screen and (min-width: 0px) and (max-width: 320px) {
    .usernameimg {
        display: none;
    }
    .username {
        display: none;
    }
    .ParentUsername {
        display: none;
    }
    .css-1vadud5-MuiTypography-root {
        display: none;
    }
    .css-yjxcff {
        display: none;
    }
}